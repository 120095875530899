import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { getGridWidth } from './theme';

export type StepFormProps = {
  header: React.ReactNode,
  stepper: React.ReactNode,
  children: React.ReactNode,
  footer: React.ReactNode,
  footerBreakpoint?: 'sm' | 'md',
  onSubmit: (evt: React.FormEvent) => void,
  disabled?: boolean,
}

export default function StepForm({ header, stepper, children, footer, footerBreakpoint = 'sm', onSubmit, disabled = false }: StepFormProps) {
  return (
    <Box
      component = "form"
      sx = {(theme) => ({
        display: 'grid',
        gridTemplateAreas: '"steps" "header" "form"',
        gridTemplateColumns: '100%',
        gap: 2,
        p: 2,
        [theme.containerQueries.up('sm')]: {
          gridTemplateAreas: '"header steps" "form form"',
          gridTemplateColumns: 'auto 36%',
          p: 0,
        },
      })}
      onSubmit = {onSubmit}
    >
      <Box sx={{ gridArea: 'steps', alignSelf: 'center' }}>
        {stepper}
      </Box>
      <Box sx={{ gridArea: 'header', alignSelf: 'center' }}>
        <Typography variant="h3">
          {header}
        </Typography>
      </Box>
      <Box sx={{ gridArea: 'form' }}>
        {children}

        <Divider sx={{ my: 3 }} />

        <Grid container columnSpacing={2} rowSpacing={2} direction="row-reverse">
          <Grid
            size = {12}
            sx = {(theme) => ({
              width: '100%',
              [theme.containerQueries.up(footerBreakpoint)]: {
                width: 'auto',
                flexBasis: 'auto',
                flexGrow: 0,
                flexShrink: 0,
                maxWidth: 'none',
              },
            })}
          >
            <Button
              variant = "contained"
              color = "primary"
              disableElevation
              type = "submit"
              disabled = {disabled}
              sx = {(theme) => ({
                width: '100%',
                [theme.containerQueries.up(footerBreakpoint)]: {
                  width: 'auto',
                },
              })}
            >
              Далее
            </Button>
          </Grid>

          <Grid
            size = {12}
            sx = {(theme) => ({
              width: '100%',
              [theme.containerQueries.up('sm')]: {
                width: 'auto',
                flexBasis: 0,
                flexGrow: 1,
                maxWidth: '100%',
              },
            })}
          >
            {footer}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}


type BackButtonProps = {
  onClick: () => void,
}

export function BackButton({ onClick }: BackButtonProps) {
  return (
    <>
      <Button
        variant = "outlined"
        color = "inherit"
        type = "button"
        onClick = {onClick}
        fullWidth
        sx = {(theme) => ({
          borderColor: '#E4E4E7',
          [theme.containerQueries.up('sm')]: {
            display: 'none',
          },
        })}
      >
        Назад
      </Button>
      <Link
        component = "button"
        type = "button"
        variant = "body1"
        onClick = {onClick}
        sx = {(theme) => ({
          display: 'none',
          [theme.containerQueries.up('sm')]: {
            display: 'inline-block',
          },
        })}
      >
        Назад
      </Link>
    </>
  );
}
